import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getCustomerTemplate, syncWithStores, uploadExportCustomerTemplateImage, getCustomerProductVariationImage } from 'src/services/admin/ProductVariationService';
import { StateTextInput } from 'src/redesign/components/Form/TextInput';
import Loader from 'src/redesign/components/Loader';
import PriceCalculation from './components/PriceCalculation';
import { getActiveStores } from 'src/base/OAuth';
import StoreNavigation from 'src/redesign/components/StoreNavigation';
import { STORE } from 'src/redesign/constants';
import CustomTooltip from 'src/redesign/components/CustomTooltip';
import IconUpload from 'src/redesign/components/Icon/Upload';
import IconDelete from 'src/redesign/components/Icon/Exit';
import UploadFile from 'src/redesign/components/UploadFile';
import ToggleSwitch from 'src/redesign/components/Form/ToggleSwitch';
import { renderScene } from 'src/services/MediaModifierService';
import FullScreenImageDialog from 'src/redesign/components/Dialog/MyArtworks/components/FullScreenImage';
import FullScreenIcon from 'src/redesign/components/Icon/FullScreen';
import Scenes from 'src/components/Scenes';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { ETSY_INITIAL_DATA, SHOPIFY_INITIAL_DATA, STORAGE_INITIAL_DATA } from './constants';
import TagInput from 'src/redesign/components/TagInput';
import { findMinPrice, mapEtsy, mapShopify, mapStorage, mapTags } from './helpers';
import SubmitPrimaryButton from 'src/redesign/components/Buttons/SubmitPrimary';
import { deleteCustomerProductVariation } from 'src/services/UserService';
import { getProductVariationImages, checkCustomerTemplateMockups } from 'src/services/admin/ProductVariationService';

const ExportProductPage = ({ history, match }) => {
  const id = match.params.id;
  const stores = getActiveStores() ?? {};
  const { shopify: isShopifyConnected, etsy: isEtsyConnected } = stores;

  const [storage, setStorage] = React.useState(STORAGE_INITIAL_DATA);
  const [shopify, setShopify] = React.useState(isShopifyConnected ? SHOPIFY_INITIAL_DATA : null);
  const [etsy, setEtsy] = React.useState(isEtsyConnected ? ETSY_INITIAL_DATA : null);

  const [image, setImage] = React.useState(null);
  const [isImageLoading, setIsImageLoading] = React.useState(false);
  const [fullScreenImage, setFullScreenImage] = React.useState(null);
  const [mainImage, setMainImage] = React.useState(null);
  const [artworkImage, setArtworkImage] = React.useState(null);
  const [mmMockups, setMMMockups] = React.useState([]);
  const [variationImages, setVariationImages] = React.useState([]);
  
  const [collectionOptions, setCollectionOptions] = React.useState([]);
  const [collections, setCollections] = React.useState([]);
  const [selectedCollections, setSelectedCollections] = React.useState(null);

  const intervalIdRef = React.useRef(false);
  const isBackgroundJobInProgressRef = React.useRef(true);
  const storageRef = React.useRef(storage);
  const shopifyRef = React.useRef(shopify);
  const etsyRef = React.useRef(etsy);

  const [attributesOptions, setAttributesOptions] = React.useState([])
  
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  
  const [selectedTab, setSelectedTab] = React.useState(STORE.storage);
  const [showShopifyTab, setShowShopifyTab] = React.useState(false);
  const [showEtsyTab, setShowEtsyTab] = React.useState(false);
  
  const [pictureUploadInProgress, setPictureUploadInProgress] = React.useState(false);
  
  const { openSnackBar } = useSnackbar();
  const product = selectedTab === STORE.storage ? storage : selectedTab === STORE.shopify ? shopify : etsy;
  storageRef.current = storage;
  shopifyRef.current = shopify;
  etsyRef.current = etsy;
  const isStorageTabSelected = selectedTab === STORE.storage;
  const isShopifyTabSelected = selectedTab === STORE.shopify;
  const isEtsyTabSelected = selectedTab === STORE.etsy;

  // #region load

  React.useEffect(() => {
    loadExportCustomerTemplate();
  }, []);

  const loadExportCustomerTemplate = async () => {
    setLoading(true);
    try {
      const { data } = await getCustomerTemplate(id);
      const { name, description, image, shopify, etsy, storage, tags, attributes } = data;

      // TODO: find valid user who have collections in order to test it in old design and implement it here
      // if(isShopifyConnected) {
      //   const { data: collections } = await getShopifyCollections();
      //   setCollectionOptions(collections.map(x => ({ key: x.id, text: x.title })));
      //   setCollections(collections);
      // }

      const variations = storage.map(element => ({
        id: 0, // should be null, but it's like this until editVariation isn't deleted from the code
        productVariation: element.productVariation,
        price: element.price,
        image: element.image,
        earned: element.earned,
        loading: false,
        variationImage: ''
      }));
      setAttributesOptions(attributes.map(x => ({ key: x.id, text: x.name })));

      await loadScenes(data.variations);
      
      // storage
      const storageMapped = mapStorage(name, description, image, storage);
      setStorage(storageMapped);

      // shopify
      const isShopifyPublished = (!!shopify || isShopifyConnected) ?? false;
      const shopifyMapped = { ...mapShopify(shopify, name, description, image, variations, attributes), isPublished: isShopifyPublished };
      setShopify(shopifyMapped);
      setShowShopifyTab(isShopifyPublished);

      // etsy
      const isEtsyPublished = !!etsy && isEtsyConnected;
      const etsyMapped = { ...mapEtsy(etsy, name, description, image, variations, attributes), isPublished: isEtsyPublished };
      setEtsy(etsyMapped);
      setShowEtsyTab(isEtsyPublished);

      setSelectedTab(isShopifyPublished ? STORE.shopify : isEtsyPublished ? STORE.etsy : STORE.storage);

      const anyImage = storage.find(x => x.image)?.image;
      if(!anyImage) {
        intervalIdRef.current = setInterval(checkForBackgroundJob, 5000);
        setIsImageLoading(true);
      }
      else {
        isBackgroundJobInProgressRef.current = false;
        setImage(anyImage);
        setImageColors(data.variations);
      }
    } catch(error){
      console.error(error);
      openSnackBar('Failed to load template!', false);
    } finally {
      setLoading(false);
    }
  }

  const loadScenes = async (variations) => {
    try {
      const variationId = variations?.[0]?.customerProductVariation?.id;
      const { data } = await getCustomerProductVariationImage({ variationId });

      if(data == null) return;

      const artworkUrl = data?.full_size_art_url;
      const mainImage =  data?.product_variation_image;
      const mmMockups = data?.customer_product_variation?.product_variation?.media_modifier_mockups ?? [];
      
      setArtworkImage(artworkUrl);
      setMainImage(mainImage);
      setMMMockups(mmMockups);
    } catch(error) {
      console.error(error);
      openSnackBar('Failed to load scenes!', false);
    }
  }

  const loadProfileImage = async (variationId) => {
    try {
      const response = await renderScene({
        selectedMockupId: history?.location?.state?.mockupIds?.[0]?.[0]?.media_modifier_mockups?.[0]?.id,
        editedImageForMockup: history.location.state?.fullSizeArt ?? artworkImage,
        variationId
      });

      setImage(response.data.sceneImage.base64);
    } catch(error) {
      console.error(error);
    }
  }

  const loadImage = (variationId, key = 0) => {
    try {
      const prodVariations = variations.filter(variation => {
        if (variation.productVariation.id !== variationId) {
          return variation;
        }
  
        variation.loading = true;
  
        return variation;
      });

      updateState({ variations: prodVariations });
      
      renderScene({
        selectedMockupId: history?.location?.state?.mockupIds?.[0]?.[key]?.media_modifier_mockups?.[0]?.id,
        editedImageForMockup: history.location.state?.fullSizeArt ?? artworkImage,
        variationId: variationId
      }).then(response => {
        const renderProdVariations = prodVariations.map(variation => {
          if (variation.productVariation.id === variationId) {
            const image = response.data.sceneImage.base64;
            variation.productImage = image;
            setImage(image);
          }

          variation.loading = false;
    
          return variation;
        });

        updateState({ variations: renderProdVariations });
      });
    } catch(error) {
      console.error(error);
      openSnackBar('Something went wrong with loading image, try again.', false);
    }
  }

  const setImageColors = variations => {
    const imageColors = variations.reduce((acc, x) => {
      const image = x.image;
      const color = x.attributes.find(y => y.color != null)?.color;
      const obj = { image, color };

      if(acc.some(y => y.color == color))
        return acc;

      return [...acc, obj];
    }, []);

    setVariationImages(imageColors);
  }

  // #endregion load

  // #region save

  const updateState = (data) => {
    if(isStorageTabSelected) {
      setStorage(prev => ({ ...prev, ...data }));
    }
    if(isShopifyTabSelected) {
      setShopify(prev => ({ ...prev, ...data }));

    }
    if(isEtsyTabSelected) {
      setEtsy(prev => ({ ...prev, ...data }));
    }
  }

  const uploadPicture = async files => {
    setPictureUploadInProgress(true);
    try {
      const file = files[0];
      const formData = new FormData();
      formData.append('image', file);
  
      const { data: { path }} = await uploadExportCustomerTemplateImage(formData);

      setImage(path);
    } catch(error) {
      openSnackBar('Failed to upload mockup!', false);
    } finally {
      setPictureUploadInProgress(false);
    }
  }

  const uploadVariationPicture = async (id, files, store) => {
    try {
      const file = files[0];
      const formData = new FormData();
      formData.append('image', file);

      const { data: { image: url } } = await uploadExportCustomerTemplateImage(formData);

      // handle variation url in variations
    } catch(error) {
      openSnackBar('Failed to upload mockup!', false);
    } finally {

    }
  }

  const deleteVariation = async variationId => {
    try {
      await deleteCustomerProductVariation(variationId, id);

      const variationsCopy = variations.filter(x => x.id !== variationId);

      updateState({ variations: variationsCopy });
    } catch (error) {
      console.error(error);
      openSnackBar('Failed to delete variation', false);
    }
  }

  const checkForBackgroundJob = async () => {
    const success = await checkMockups();
    if (success) {
      clearInterval(intervalIdRef.current);
      
      const { data: { image, variations, storage: backendVariations } } = await getCustomerTemplate(id);
      setImage(backendVariations?.[0]?.image);
      
      const storage = storageRef.current;
      setStorage({
        ...storage,
        image: image,
        variations: storage.variations?.map(x => ({
          ...x,
          image: backendVariations.find(y => y.id === x.id)?.image
        }))
      });
      
      const shopify = shopifyRef.current;
      setShopify({
        ...shopify,
        variations: shopify.variations.map((x, i) => ({
          ...x,
          image: backendVariations?.[i]?.image
        }))
      });

      const etsy = etsyRef.current;
      setEtsy({
        ...etsy,
        variations: etsy.variations.map((x, i) => ({
          ...x,
          image: backendVariations?.[i]?.image
        }))
      });

      setImageColors(variations);
      setIsImageLoading(false);
    }
  };

  React.useEffect(() => {
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  const checkMockups = async () => {
    try {
      const { data: { success } } = await checkCustomerTemplateMockups(id);
      isBackgroundJobInProgressRef.current = !success;
      return success;
    } catch(error) {
      console.error(error);
      openSnackBar('Something went wrong with checking mockups, try again', false);
    }
  }

  const saveTemplate = async () => {
    setSaving(true);
    try {
      const shopifyMaybe = shopify && (shopify.id || (shopify.id == null && shopify.isPublished)) ? { shopify: { ...shopify, tags: mapTags(shopify.tags)}} : {};
      const etsyMaybe = etsy && (etsy.id || (etsy.id == null && etsy.isPublished)) ? { etsy: { ...etsy, tags: mapTags(etsy.tags)}} : {};

      const data = {
        storage,
        ...shopifyMaybe,
        ...etsyMaybe,
      };

      await syncWithStores(id, data);
      openSnackBar('Successfully saved!');
      history.push('/product-templates/storage');
    } catch(error) {
      console.error(error);
      openSnackBar('Something went wrong with syncing, try again.', false);
    } finally {
      setSaving(false);
    }
  }

  // #endregion save

  const salesChannelEtsy = (
    <div
      className={classNames('sales-channel', {'sales-channel-disabled': !isEtsyConnected, 'sales-channel-selected': showEtsyTab })}
      onClick={() => {
        if(isEtsyConnected) {
          if(!showEtsyTab)
            setSelectedTab(STORE.etsy);
          else if(selectedTab === STORE.etsy)
            setSelectedTab(STORE.storage);
          setEtsy(prev => ({ ...prev, isPublished: !prev.isPublished }));
          setShowEtsyTab(prev => !prev)
        }
      }}>
    <Image alt={getImageAlt()} className='sales-channel-etsy' src='/images/etsy.png'/>
    <ToggleSwitch checked={showEtsyTab} onChange={()=>{}} withGradient/>
  </div>
  );
  const salesChannelShopify = (
    <div
      className={classNames('sales-channel', {'sales-channel-disabled': !isShopifyConnected, 'sales-channel-selected': showShopifyTab })}
      onClick={() => {
        if(isShopifyConnected) {
          if(!showShopifyTab)
            setSelectedTab(STORE.shopify);
          else if(selectedTab === STORE.shopify)
            setSelectedTab(STORE.storage);
          setShopify(prev => ({ ...prev, isPublished: !prev.isPublished }));
          setShowShopifyTab(prev => !prev);
        }
      }}>
      <Image alt={getImageAlt()} className='sales-channel-shopify' src='/images/shopify.png'/>
      <ToggleSwitch checked={showShopifyTab} onChange={()=>{}} withGradient/>
    </div>
  );

  // #region tags-helpers

  const tags = isShopifyTabSelected ? shopify?.tags : etsy?.tags;
  const onAddTag = newTagName => {
    const updatedTags = [ ...tags, { id: uuid(), name: newTagName }];
    updateState({ tags: updatedTags });
  };

  const onDeleteTag = tag => {
    const filteredTags = tags.filter(x => x.id !== tag.id);
    updateState({ tags: filteredTags });
  }

  // #endregion tags-helpers

  // #region attributes-helpers

  const attributes = isShopifyTabSelected ? shopify?.options : etsy?.options;

  const isAttributesDisabled = (isShopifyTabSelected ? attributes?.length === 3 : attributes?.length === 2) || attributes?.length === attributesOptions.length;

  const onAddAttribute = newAttribute => {
    const updatedAttributes = [...attributes, { id: newAttribute.key, name: newAttribute.text }];
    updateState({ options: updatedAttributes });
  }

  const onDeleteAttribute = attribute => {
    const filteredOptions = attributes.filter(x => x.id !== attribute.id);
    updateState({ options: filteredOptions });
  }

  // #endregion attribute-helpers

  // #region variations-helpers

  const variations = (isStorageTabSelected ? storage?.variations : isShopifyTabSelected ? shopify?.variations : etsy?.variations) ?? [];

  const setAllVariationPrices = earned => {
    const variationsCopy = variations.map(x => ({ ...x, earned }));
    updateState({ variations: variationsCopy, earned });
  }

  const setVariationPrice = (id, earned) => {
    const variationsCopy = variations.map(x => x.id === id ? ({ ...x, earned }) : x);
    updateState({ variations: variationsCopy });
  }

  // #endregion variations-helpers
  
  // #region name-description-helpers

  const setName = (name) => {
    updateState({ name });
  }

  const setDescription = (description) => {
    updateState({ description });
  }

  // #endregion name-description-helpers

  const minPrice = findMinPrice(variations);
  const earnedPrice = isStorageTabSelected ? storage?.earned : isShopifyTabSelected ? shopify?.earned : etsy?.earned;

  return (
    <ApplicationPage selectedTab='product-catalog'>
      <ApplicationPageContent title='Product Details' className='export-product-container'>
      {loading ? (
        <Loader width={200} height={200} color={colors.primary}/>
      ) : (

              <>
                <StoreNavigation
                  isShopifyConnected={showShopifyTab}
                  isEtsyConnected={showEtsyTab}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
                <div className='product-details-container'>
                  <div className='product-details-image-section'>
                    <div className='details-image-title'>
                      Product
                    </div>
                    <div className='details-image-container' onClick={() => image && setFullScreenImage(image)}>
                      {isImageLoading ? (
                          <Loader width={200} height={300} color={colors.primary} />
                      ) : (
                        <>
                          <Image alt={getImageAlt()} className='details-image' src={image} />
                          <div className='details-image-hover-container'>
                            View Full-size <FullScreenIcon width={18} height={18} color={colors.white}/>
                          </div>
                        </>
                      )}
                    </div>
                    {/* TODO: add upload your own mockup later */}
                    {/* <UploadFile setFiles={uploadPicture} accept='.png'>
                      <SubmitButton width={325} text={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ minWidth: 24 }}>
                            <IconUpload width={24} height={24} color={colors.white} />
                          </div>
                          Upload Your Own Mockup
                        </div>
                      } loading={pictureUploadInProgress}/>
                    </UploadFile> */}
                  </div>
    
                  <div className='sales-channels-section'>
                    <div className='sales-channels-title'>
                      Sales channels
                    </div>
                    {isEtsyConnected ? (
                      salesChannelEtsy
                    ) : (
                    <CustomTooltip text={'Please go to Stores Tab and connect your store in order to start exporting and importing Products from this channel'}>
                      {salesChannelEtsy}
                    </CustomTooltip>
                    )}
                    {isShopifyConnected ? (
                      salesChannelShopify
                    ) : (
                      <CustomTooltip text={'Please go to Stores Tab and connect your store in order to start exporting and importing Products from this channel'}>
                        {salesChannelShopify}
                      </CustomTooltip>
                    )}
                  </div>
    
                  <div className='details-form'>
                    <div className='field-container'>
                      <div className='field-label'>
                        Internal Name
                      </div>
                      <StateTextInput name='name' placeholder='' value={product?.name ?? ''} className='field-input' onChange={setName}/>
                    </div>
                    <div className='field-container'>
                      <div className='field-label'>
                        Description
                      </div>
                      <StateTextInput name='description' placeholder='' value={product?.description ?? ''} className='field-input' type='textarea' onChange={setDescription}/>
                    </div>
                  </div>
                </div>

                {variationImages.length > 0 && !isImageLoading && !loading && (
                  <div className='variations-images-container'>
                    <div className='variations-images-label'>
                      Colors
                    </div>
                    <div className='variations-images-row'>
                      {variationImages.map(x => (
                        <div
                          onClick={() => setImage(x.image)}
                          className='variation-image-option-container'
                        >
                          <div
                            className={classNames(
                              'variation-image-option', {
                                'variation-image-option-selected': image == x.image
                              })}
                            style={{ backgroundColor: x.color }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {variations?.length > 0 && image != null && mmMockups?.[0]?.scenes?.length > 0 && artworkImage != null ?
                  <Scenes 
                      variationId={variations?.[0]?.productVariation?.id}
                      fullSizeArt={artworkImage ?? history.location.state?.fullSizeArt}
                      mainMockup={image}
                      media_modifier_mockups={mmMockups} /> 
                    : ''}

                {(isShopifyTabSelected || isEtsyTabSelected) && (
                  <>
                    <TagInput title='Tags' tags={tags} onAddTag={onAddTag} onDeleteTag={onDeleteTag}/>

                    <TagInput title='Options' tags={attributes} onAddTag={onAddAttribute} onDeleteTag={onDeleteAttribute} disabled={isAttributesDisabled} options={attributesOptions.filter(x => attributes.every(a => x.key !== a.id))} isDropdownInput />

                    {/* <TagInput title='Collection' tags={collections} onAddTag={x => setCollections(prev => [...prev, x])} onDeleteTag={x => setCollections(prev => prev.filter(t => t !== x))} options={collectionOptions}/> */}
                  </>
                )}

                {isShopifyTabSelected && (
                  <>
                    {/* TODO: ADD COLLECTIONS DROPDOWN FOR SHOPIFY */}
                    {/* <Dropdown
                      
                    /> */}
                    <div className='export-as-draft-container'>
                      <div className='export-as-draft-text'>
                        Note: your Manual collections are listed here, if you want to export your product to Automatic collection simply leave the Collection column empty and based on your Automatic collection conditions we will update the right collection.
                        <br />
                        For example, if the condition for one of your Automatic collections named Apparel is Tag equals Shirts, add tag Shirts and leave Collection field empty - we will automatically update the Apparel collection.
                      </div>
                      <ToggleSwitch label='Export as Draft' checked={shopify?.isDraft} onChange={() => updateState({ isDraft: !shopify?.isDraft })}/>
                    </div>
                  </>
                )}

    
                <div className='variants-and-price-container'>
                  <div className='template-price-general'>
                    <div className='template-price-label'>
                      Product template price:
                    </div>
                      <PriceCalculation surgeprintPrice={minPrice} earnedPrice={earnedPrice} onEdit={setAllVariationPrices} />
                  </div>
                  <div className='variants-list'>
                    {variations?.map((x, key) => (
                      <div className='exported-product-variant' key={uuid()}>
                        <div className='export-product-left-section'>
                          {/* TODO: add upload your own mockup later */}
                          {/* <UploadFile setFiles={files => uploadVariationPicture(x?.id, files, null)}> */}
                          {/* {(x.productImage || x.image) ? (
                            <Image alt={getImageAlt()} id={x.productVariation.id} className='exported-product-image' src={x.productImage ? x.productImage : x.image} />
                            ) : isImageLoading ? (
                              <Loader width={50} height={70} color={colors.primary} className='exported-product-loader' />
                            ) : 
                            (
                              <SubmitPrimaryButton
                                text='Show Mockup'
                                className='export-product-render-button'
                                onClick={() => loadImage(x.productVariation.id, key)}
                                loading={x.loading}
                                disabled={x.loading}
                                style={{ borderRadius: '13.2679px 0 0 13.2679px' }}
                                width={100}
                              />
                            )
                          } */}
                          <div className='exported-product-name'>
                            {x?.productVariation?.name}
                          </div>
                        </div>
                        <PriceCalculation surgeprintPrice={x?.price} earnedPrice={x?.earned ?? 0} showOnlyIcons onEdit={v => setVariationPrice(x.id, v)}/>
                        {/* TODO: uncomment when deleting is desired */}
                        {/* {x.id !== 0 ? (
                          <button className='exported-delete-icon' onClick={() => deleteVariation(x?.productVariation?.id)}>
                            <IconDelete width={18} height={18} color={colors.red} />
                          </button>
                        ) : null} */}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            
      )}
      <div className='save-to-store-container'>
        <SubmitPrimaryButton
          text='Previous step'
          className='save-to-store-button'
          disabled={loading}
          onClick={() => {
            const categoryId = mainImage?.product_variation?.product?.category?.id;
            const productId = mainImage?.product_variation?.product?.id;

            history.push('/product-selection/' + categoryId, {
              selectedBrandId: productId
            })
          }}
        />
        <SubmitPrimaryButton
          text='Save to stores'
          className='save-to-store-button'
          onClick={saveTemplate}
          loading={saving}
          disabled={loading || saving || isBackgroundJobInProgressRef.current}
          tooltipText={isBackgroundJobInProgressRef.current ? `Please give us a few more moments, we're creating awesome mockups for you` : undefined}
          tooltipPlacement='left'
          width={140}
        />
      </div>
      {fullScreenImage && (
        <FullScreenImageDialog
          image={fullScreenImage}
          onClose={() => setFullScreenImage(null)}
        />
      )}
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default withRouter(ExportProductPage);