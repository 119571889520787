import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import './style.scss';

const ListTable = ({ items, columns }) => {
  return (
    <div className='list-table-container'>
      <div className='list-table-row'>
        {columns.map(x => (
          <div key={uuid()} className='list-table-header-item' style={{ flex: x.flex }}>
            {x.text}
          </div>
        ))}
      </div>
      {items.map(x => (
          <div key={uuid()} className='list-table-row'>
            {columns.map(c => (
              <div key={uuid()} className={classNames('list-table-body-item', {'list-table-body-item-price': c.isPrice })} style={{ flex: c.flex }}>
                {x[c.name]}
              </div>
            ))}
          </div>
        ))}
    </div>
  )
};

export default ListTable;