import React from 'react';
import IconTag from 'src/redesign/components/Icon/Tag';
import IconCircleExit from 'src/redesign/components/Icon/CircleExit';
import Dropdown from 'src/redesign/components/Dropdown';
import { v4 as uuid } from 'uuid';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { debounce } from 'lodash';

const TagInput = ({ title, tags, options = [], onAddTag, onDeleteTag, disabled, isDropdownInput }) => {
  const [inputValue, setInputValue] = React.useState('');
  const hasOptions = isDropdownInput;

  const onChangeInput = React.useCallback(
    debounce((text) => {
      onAddTag(text);
      setInputValue('');
    }, 1000)
  ,[]);

  return (
    <div className='tag-input-container'>
      <div className='tag-input-title-container'>
        <div className='tag-input-title'>
          {title}
        </div>
        {!hasOptions && (
          <div className='tag-input'>
            <input className='tag-input-input' placeholder='Add a tag...' value={inputValue} onChange={e => {
              const newText = e.target.value;
              setInputValue(newText);
              onChangeInput(newText);
            }} disabled={disabled}/>
            <div className='tag-input-icon'>
              <IconTag width={15} height={15} color={colors.gray939}/>
             </div> 
          </div> 
        )}
      </div>
      <div className ='tags-row-container'>
        {tags.map(x => (
          <div className='tag-container' key={uuid()}>
            <IconTag width={15} height={15} color={colors.white}/>
            <div className='tag-text'>
              {Object.keys(x)?.length > 0 ? x?.name : x}
            </div>
            <div className='tag-exit-icon' onClick={() => onDeleteTag(x)}>
              <IconCircleExit width={13} height={13} color={colors.white} secondColor={colors.darkBlue} />
            </div>
          </div>
        ))}
      </div>
      {hasOptions && (
        <Dropdown 
          label=''
          options={options}
          onSelect={x => onAddTag(x)}
          disabled={disabled}
          width={300}
          height={24}
        />
      )}
    </div>
  )
}

export default TagInput;