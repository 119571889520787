import React from 'react';
import { v4 as uuid } from 'uuid';

import ApplicationPageContent from 'src/redesign/components/ApplicationPageContent';
import ApplicationPage from 'src/redesign/components/ApplicationPage';
import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const WarehousePROPage = () => {

  const cards = [
    {
      image: '/images/warehouse1.png',
      title: 'Warehouses in the USA and EU/Non-EU',
      description: 'Sell globally and reach buyers all over the world'
    },
    {
      image: '/images/warehouse2.png',
      title: 'Same-day shipping',
      description: 'Orders placed by 12 PM ship out on the some day'
    },
    {
      image: '/images/warehouse3.png',
      title: 'Automatic order fulfillment',
      description: 'We integrate with top ecommerce platforms'
    }
  ]

  const items = [
    {
      text: <div className='bullet-text'>
        Click <span className='bold'>Add new product</span> and tell us about the items you want to store
      </div>
    },
    {
      text: <div className='bullet-tex'>
        Wait for us to review them, we’ll email you in 2 business days to let you know if they're approved or declined
      </div>
    },
    {
      text: <div className='bullet-tex'>
        Ship us your products after we've approved them for warehousing
        </div>
    },
    {
      text: <div className='bullet-tex'>
        Click <span className='bold'>Add new shipment</span> to let us know that the shipment's on its way
      </div>
    },
    {
      text: <div className='bullet-tex'>
        Hold on until we receive, register, and stock your products—we’ll email you once that’s done
      </div>
    },
    {
      text: <div className='bullet-tex'>
          Add warehousing product to your store - go to <span className='bold'>Product Hub {'>'} export warehousing product to Shopify</span> or go to Place an Order and choose from Warehouse Items to submit an order manually.
        </div>
    },
    {
      text: <div className='bullet-tex'>
        Start selling and manage your warehoused stock under <span className='bold'>Warehouse {'>'} Inventory</span>
        </div>
    }
  ]

  return (
    <ApplicationPage selectedTab='warehouse'>
      <ApplicationPageContent title='Warehouse PRO'>
        <div className='warehouse-pro'>
          <div className='title'>
            Store your products at our partner warehouses
          </div>
          <div className='description'>
            Game-changer! 
            <br />
            <br />
            Surge: Print on Demand is the only platform that allows its customers to store items on 3 continents in 4 modern warehouses and link them with 40+ printing facilities across the world!
            <br/>
            Cut the long delivery times for your drop shipped products. 
            <br />
            <br />
            Let us fulfill and ship all your orders under your own brand!
            <br />
            <br />
            You can store these products in one of 4 modern warehouses located in the US, China, EU, and Non-EU strategic locations and you'll never again need to worry about production delays, holiday cut-offs, and out of stock nightmares! We can also store your handmade items or items which were produced by your vendors.
          </div>
          <div className='cards'>
            {cards.map(({ image, title, description}) => (
              <div key={uuid()} className='card'>
                <Image alt={getImageAlt()} src={image} className='image'/>
                <div className='card-text'>
                  <div className='card-title'>
                    {title}
                  </div>
                  <div className='card-description'>
                    {description}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='how-it-works'>
            <div className='title'>
              How does it work?
            </div>
            <div className='list'>
              {items.map(({ text }, i) => (
                <div key={uuid()} className='list-item'>
                  <div className='bullet'>{i + 1}</div>
                  {text}
                </div>
              ))}
            </div>
          </div>
          <div className='contact'>
            Contact your dedicated Account Manager to Unlock this Feature or  support@surgeprint.com  for more information about the pricing and product storing requirements.
          </div>
        </div>
      </ApplicationPageContent>
    </ApplicationPage>
  )
}

export default WarehousePROPage;