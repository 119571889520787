import React from 'react';
import { v4 as uuid } from 'uuid';
import { withRouter } from 'react-router';
import Breadscrumb from 'src/redesign/components/Breadcrumb';
import Loader from 'src/redesign/components/Loader';
// import Searchbox from 'src/redesign/components/Searchbox';
import Page from 'src/redesign/components/Page';
import { CATEGORIES_TABS } from 'src/redesign/constants';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import { getCategoriesTree } from 'src/services/CategoryService';
import ProductItem from 'src/redesign/components/ProductItem';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { mapNameToUrl } from 'src/redesign/helpers';

const ProductCatalogPageCategory = ({ match: { params: { tab }} }) => {
  const [loading, setLoading] = React.useState(false);
  const [searchboxText, setSearchboxText] = React.useState('');

  const [categories, setCategories] = React.useState([]);
  const [filteredCategories, setFilteredCategories] = React.useState([]);
  const [topCategories, setTopCategories] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  const selectedTab = CATEGORIES_TABS.find(x => x.key === tab);

  const selectedCategory = topCategories.find(x => x.name === selectedTab.text);
  const tabName = selectedCategory?.name;
  const tabDescription = selectedTab?.description;

  const selectedCategoryId = topCategories.find(x => x.name === selectedTab.text)?.id;
  const selectedCategoiresByTab = filteredCategories.filter(x => x?.parent_category?.id === selectedCategoryId);

  React.useEffect(() => {
    loadCategories();
  }, []);

  React.useEffect(() => {
    setFilteredCategories(categories.filter(x => {
      const name = x?.name?.toLowerCase();
      return name.includes(searchboxText);
    }));
  }, [searchboxText]);

  const loadCategories = async () => {
    setLoading(true);
    try {
      const { data } = await getCategoriesTree();
      const topCategories = data.filter(x => !x.parent_category);
      
      setCategories(data);
      setFilteredCategories(data);
      setTopCategories(topCategories);
    } catch {
      openSnackBar('Failed to load categories', false);
    } finally {
      setLoading(false);
    }
  }

  return <Page
    seoTitle={selectedTab.seoTitle}
    seoDescription={selectedTab.seoDescription}
  >
    <div className='product-catalog-category'>
      {loading ? (
        <Loader
          width={300}
          height={400}
          color={colors.primary}
        />
      ) : (
        <>
          <div className='product-catalog-breadscrumb-searchbox-container'>
            <Breadscrumb
              links={[
                {
                  to: '/product-catalog-website', text: 'Catalog',
                },
                {
                  to: `/product-catalog-website/${tab}`, text: tabName ?? '',
                }
              ]}
            />
            {/* <Searchbox
              placeholder='Type to search'
              className='product-catalog-category-all-search-box'
              inputClassName='product-catalog-category-all-search-input'
              onChange={setSearchboxText}
            /> */}
          </div>
          <h1 className='catalog-category-name'>
              {tabName}
          </h1>
          {tabDescription && (
            <div className='catalog-category-description'>
              {tabDescription}
            </div>
          )}
          <div className='catalog-category-product-list-container'>
            {selectedCategoiresByTab.map(x => <ProductItem key={uuid()} name={x?.name} price={`$${x?.min_price} - $${x?.max_price}`} url={process.env.REACT_APP_baseUrl + x?.category_image?.url} to={`/product-catalog-category/${mapNameToUrl(x.name)}/${x.id}`} />)}
          </div>
        </>
      )}
    </div>
  </Page>
}

export default withRouter(ProductCatalogPageCategory);