import React from 'react';
import ReactDOM from 'react-dom';
import IconExit from 'src/redesign/components/Icon/Exit';
import Navigation from 'src/redesign/components/Navigation';
import ProductDetailsVariants from './components/ProductVariants';
import ProductDetailsProduction from './components/Production';
import ProductDetailsShipping from './components/Shipping';

import './style.scss';
import colors from 'src/scss/_colors.scss';
import { renderTab } from 'src/redesign/helpers';

const ProductDetailsDialog = ({ id, onClose, preselectedTab = 0 }) => {
  const [selectedTab, setSelectedTab] = React.useState(preselectedTab);

  return ReactDOM.createPortal(
    <div className='product-details-dialog-container'>
      <div className='dialog-content'>
        <div className='dialog-header'>
          <div className='dialog-header-title'>
            Printed by Surge Print
          </div>
          <div className='dialog-header-close' onClick={onClose}>
            <IconExit width={14} height={14} color={colors.darkBlue}/>
          </div>
        </div>
        <Navigation tabs={[
          // TODO: comment for now until endpoint for grouping is done
          // {
          //   text: 'Product variants',
          //   to: ''
          // },
          {
            text: 'Shipping',
            to: ''
          },
          {
            text: 'Production',
            to: ''
          }
        ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {renderTab([
          // <ProductDetailsVariants id={id} />,
          <ProductDetailsShipping id={id} />,
          <ProductDetailsProduction />,
        ], selectedTab)}
      </div>
    </div>
  , document.getElementById('dialog-root'));
};

export default ProductDetailsDialog;