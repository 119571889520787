import AttributeType from "src/constants/AttributeType";
import { IMAGE_ALT } from "../constants";

export function renderTab(tabs, selectedTab) {
  return tabs[selectedTab];
}

export function roundStringPrice(string) {
  return Number.parseFloat(string)?.toFixed(2);
}

export function mapAttributes(productAttributes) {
  const uniqueAttributes = _.uniq(productAttributes?.map(x => x.attribute_value?.attribute?.name));
  return uniqueAttributes.map(x => ({
    name: x,
    values: productAttributes?.filter(a => a.attribute_value?.attribute?.name === x)?.map(a => a?.attribute_value),
    type: productAttributes?.find(a => a.attribute_value?.attribute?.name === x)?.attribute_value?.attribute?.attribute_type,
    colorNames: productAttributes?.filter(a => a.attribute_value?.attribute?.name === x)?.map(a => a?.attribute_value?.customer_color_name),
  }));
}

export function mapFilters(productFilters) {
  const uniqueFilters = _.uniq(productFilters?.map(x => x.attributeValue?.attribute?.name));

  return uniqueFilters.map(x => ({
    name: x,
    values: productFilters?.filter(a => a.attributeValue?.attribute?.name === x)?.map(a => a?.attributeValue).sort((a, b) => {
      const isColorType = a?.attribute?.attributeType !== AttributeType.STRING;
      if(isColorType)
        return a?.customerColorName?.toLowerCase() < b?.customerColorName?.toLowerCase() ? -1 : 1;
      return a?.attribute?.name?.toLowerCase() < b?.attribute?.name?.toLowerCase() ? -1 : 1;
    }),
    type: productFilters?.find(a => a.attributeValue?.attribute?.name === x)?.attributeValue?.attribute?.attributeType,
    colorNames: productFilters?.filter(a => a.attributeValue?.attribute?.name === x)?.map(a => a?.attributeValue?.customerColorName),
  }))  
}

export function mapNameToUrl(name) {
  return name?.replaceAll(' ', '-').replaceAll('%', '')?.toLowerCase();
}

export function getImageAlt() {
  return IMAGE_ALT[Math.floor(Math.random()*6)];
}