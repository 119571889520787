import React from 'react';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';
import Page from 'src/redesign/components/Page';
import Loader from 'src/redesign/components/Loader';
import useSnackbar from 'src/redesign/hooks/useSnackbar';
import Breadscrumb from 'src/redesign/components/Breadcrumb';
// import Searchbox from 'src/redesign/components/Searchbox';
import ProductItem from 'src/redesign/components/ProductItem';
import FilterPanel from 'src/redesign/components/FilterPanel';
import { getCategoriesTree, getSubCategoryFilters, getSubCategoryProducts } from 'src/services/CategoryService';
import { getBrands } from 'src/services/admin/BrandService';
import { mapNameToUrl, roundStringPrice } from 'src/redesign/helpers';

import './style.scss';
import colors from 'src/scss/_colors.scss';

const ProductCatalogCategoryBrand = ({ match: { params: { id } } }) => {
  const [loading, setLoading] = React.useState(false);
  const [filtersLoading, setFiltersLoading] = React.useState(false);
  const [searchboxText, setSearchboxText] = React.useState('');

  const [parentCategory, setParentCategory] = React.useState({});
  const [subCategory, setSubCategory] = React.useState({});

  const [brands, setBrands] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [filteredProducts, setFilteredProducts] = React.useState([]);

  const [filters, setFilters] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [brandFilters, setBrandFilters] = React.useState([]);
  const [brandSelectedFilters, setBrandSelectedFilters] = React.useState([]);

  const { openSnackBar } = useSnackbar();

  React.useEffect(() => {
    loadFilters();
  }, []);


  React.useEffect(() => {
    setFilteredProducts(products.filter(x => {
      const name = x?.name?.toLowerCase();
      return name.includes(searchboxText);
    }));
  }, [searchboxText]);

  const loadProducts = async (filters, brands) => {
    setLoading(true);
    try {
      const { data = [] } = await getSubCategoryProducts(id, {
        filters
      });
      const { data: categories } = await getCategoriesTree();
      
      const category = categories.find(x => x.id == id);
      setSubCategory(category);
      setParentCategory(category?.parent_category);

      const mappedData = data.map(x => ({
        ...x,
        brand: brands.find(b => b.id == x.brand_id)
      }));

      if(filters.length === 0) {
        const brandFilters = brands.reduce((acc, { id, name }) => 
          mappedData.find(d => id === d.brand?.id)
          ? [
            ...acc,
            {
              name,
              id
            }
          ] : acc
        ,[]);
        setBrandFilters(brandFilters);
      }
      
      setProducts(mappedData);
      setFilteredProducts(mappedData);
    } catch (error) {
      openSnackBar('Failed to load products!', false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const loadFilters = async () => {
    setFiltersLoading(true);
    try {
      const { data: filters } = await getSubCategoryFilters(id);
      const { data: brands } = await getBrands();

      setFilters(filters);
      setBrands(brands);
      loadProducts([], brands);
    } catch(error) {
      openSnackBar('Failed to load filters!', false);
      console.error(error);
    } finally {
      setFiltersLoading(false);
    }
  }

  function filterByBrand(selectedBrands) {
    if(selectedBrands.length > 0) {
      setFilteredProducts(products.filter(x => selectedBrands.some(id => x.brand.id === id)));
    } else {
      setFilteredProducts(products);
    }
  }

  return <Page
      seoTitle={subCategory?.seo_title}
      seoDescription={subCategory?.seo_description}
    >
      <div className='product-catalog-category-container'>
        <div className='product-catalog-category-breadscrumb-searchbox-container'>
          <Breadscrumb
            loading={loading}
            links={[
              {
                to: '/product-catalog-website', text: 'Catalog',
              },
              {
                to: `/product-catalog-website/${parentCategory?.name?.toLowerCase()?.replaceAll(' ','-')}`,
                text: parentCategory?.name ?? '',
              },
              {
                to: `/product-catalog-category/${mapNameToUrl(subCategory?.name)}/${subCategory?.id}`, text: subCategory?.name ?? '',
              }
            ]}
          />
          {/* <Searchbox
            placeholder='Type to search'
            className='product-catalog-category-all-search-box'
            inputClassName='product-catalog-category-all-search-input'
            onChange={setSearchboxText}
          /> */}
        </div>
        <h1 className='catalog-category-brand-name'>
          {subCategory?.name}
        </h1>
        <div className='catalog-category-product-brand-container'>
          <FilterPanel
            loading={filtersLoading}
            isDataLoading={loading}
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={selectedFilters => {
              setSelectedFilters(selectedFilters);
              loadProducts(selectedFilters, brands);
            }}
            brandFilters={brandFilters}
            selectedBrandFilters={brandSelectedFilters}
            setSelectedBrandFilters={selectedBrandFilters => {
              setBrandSelectedFilters(selectedBrandFilters);
              filterByBrand(selectedBrandFilters);
            }}
          />
          {loading ? (
          <Loader
            width={300}
            height={300}
            color={colors.primary}
          />
          ) : (
            <div className='catalog-category-product-brand-list-container'>
                {filteredProducts.map(x => <ProductItem key={uuid()} name={x?.name} price={`$${roundStringPrice(x?.price)}`} brandName={x?.brand?.name} url={process.env.REACT_APP_baseUrl + x?.productImage} to={`/product/${mapNameToUrl(x.name)}/${id}/${x.id}`} />)}
            </div>
          )}
        </div>
      </div>
    </Page>
}

export default withRouter(ProductCatalogCategoryBrand);