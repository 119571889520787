import React from 'react';
import { string, number } from 'prop-types';

const YoutubeVideo = ({ className, embedId, width, height }) => (
  <div className={className}>
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
)

YoutubeVideo.propTypes = {
  className: string,
  width: number.isRequired,
  height: number.isRequired,
  embedId: string.isRequired
}

export default YoutubeVideo;