import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getProductVariationsAdmin(id) {
    return await request('/api/get_product_variations_admin/' + id , [],  HttpMethod.GET);
}
export async function getProductVariationsForAttributes(data) {
    return await request('/api/get_product_variations_for_attributes', data,  HttpMethod.POST);
}
export async function checkProductVariationsForAttributes(data) {
    return await request('/api/check_product_variations_for_attributes', data,  HttpMethod.POST);
}

export async function syncProductVariationsWithMockups(data) {
    return await request('/api/sync-product-variations-with-mockups' , data,  HttpMethod.POST);
}

export async function syncProductVariationsWithDesignTemplate(data) {
    return await request('/api/sync-product-variations-with-design-template?_method=PUT' , data,  HttpMethod.POST);
}

export async function getProductVariationImages(data) {
    return await request('/api/get_product_variation_images' , data,  HttpMethod.POST);
}
export async function getCustomerProductVariationImage({ variationId }) {
    return await request(`/api/get_customer_product_variation_image/${variationId}`, [], HttpMethod.GET);
}
export async function getProductVariationImagesFromCustomerProductVariations(data) {
    return await request('/api/get_product_variation_images_for_customer_product_variations' , data,  HttpMethod.POST);
}
export async function editSingleVariationInfo(data) {
    return await request('/api/edit_single_product_variation_info' , data,  HttpMethod.POST);
}
export async function editMultiVariationInfo(data) {
    return await request('/api/edit_multi_product_variation_info' , data,  HttpMethod.POST);
}
export async function editMultiVariationPrice(data) {
    return await request('/api/edit_multi_product_variation_price' , data,  HttpMethod.POST);
}
export async function editSingleVariationPrice(data) {
    return await request('/api/edit_single_product_variation_price' , data,  HttpMethod.POST);
}
export async function createEditProductVariationShipping(data) {
    return await request('/api/create_edit_product_variation_shipping' , data,  HttpMethod.POST);
}
export async function uploadProductVariationImage(data) {
    return await request('/api/upload_variation_image' , data,  HttpMethod.POST);
}
export async function uploadArtworkImage(data) {
    return await request('/api/upload_artwork_image' , data,  HttpMethod.POST);
}
export async function getShippingPricingForProductVariation(id) {
    return await request('/api/get_shipping_pricing_product_variation/' + id , id,  HttpMethod.GET);
}
export async function toggleVariationAvailability(id) {
    return await request('/api/toggle_variation_availability/' + id , [],  HttpMethod.PUT);
}

export async function deleteVariationImage(id) {
    return await request('/api/delete_variation_image/' + id , [],  HttpMethod.PUT);
}
export async function replaceVariationImage(data) {
    return await request('/api/replace_variation_image' , data,  HttpMethod.POST);
}
export async function uploadMergedImages(data) {
    return await request('/api/upload-merged' , data,  HttpMethod.POST);
}
export async function createImage(data) {
    return await request('/api/create-image', data, HttpMethod.POST);
}
export async function updateCustomerTemplate(data) {
    return await request('/api/update_customer_template' , data,  HttpMethod.POST);
}

export async function getTemplatesForUser(page, data) {
    return await request('/api/get_templates_for_user/' + page, data,  HttpMethod.GET);
}

export async function importProductsFromShopify() {
    return await request('/api/user/shopify/products/import' , [],  HttpMethod.POST);
}

export async function importProductsFromEtsy() {
    return await request('/api/user/etsy/products/import' , [],  HttpMethod.POST);
}

export async function getImportedProduct(data, source) {
    return await request('/api/user/' + source + '/products/imported' , data,  HttpMethod.GET);
}

export async function getCustomerProductVariationsById(data) {
    return await request('/api/get_customer_product_variations_by_id' , data,  HttpMethod.POST);
}

export async function getOrderCustomerProductVariationsById(data) {
    return await request('/api/get_order_customer_product_variations_by_id' , data,  HttpMethod.POST);
}

export async function deleteCustomerTemplate(data) {
    return await request('/api/delete_customer_template' , data,  HttpMethod.PUT);
}

export async function uploadExportCustomerTemplateImage(data){
    return await request('/api/temporary/image-path', data, HttpMethod.POST);
}

export async function getShopifyCollections(data){
    return await request('/api/get_shopify_collections', data, HttpMethod.GET);
}

export async function getOtherVariation(data) {
    return await request('/api/get_other_variations', data, HttpMethod.POST);
}

export async function saveCustomerTemplate(id, data) {
    return await request('/api/customer-template/' + id, data, HttpMethod.PUT);
}

export async function syncWithStores(id, data) {
    return await request('/api/customer-template/' + id + '/sync-with-store', data, HttpMethod.POST);
}

export async function getCustomerTemplate(id) {
    return await request('/api/customer-template/' + id, [], HttpMethod.GET);
}

export async function checkCustomerTemplateMockups(id) {
    return await request(`/api/customer-template/${id}/check-mockups`, [], HttpMethod.GET);
}

export async function getUnMappedTemplates() {
    return await request('/api/templates/variations/unused', [], HttpMethod.GET);
}
