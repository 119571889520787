import React from 'react';

import Image from 'src/redesign/components/Image';
import { getImageAlt } from 'src/redesign/helpers';

import './style.scss';

const SummaryItem = ({ item }) => {
  const image = item.customer_product_variation_images[0] ? item.customer_product_variation_images[0].merged_image : '';
  const imageUrl = process.env.REACT_APP_baseUrl + image;

  return (
    <div className='summary-item'>
      <div className='summary-item-header'>
        <Image alt={getImageAlt()} className='summary-item-image' src={imageUrl} />
        <div className='summary-item-text'>
          <div className='summary-item-title'>
            {item.customer_product_variation.product_variation.product.name}
          </div>
          <div className='summary-item-description'>
            Quantity: {item.quantity}
          </div>
        </div>
      </div>
      <div className='summary-item-price'>
          ${(item.customer_product_variation.product_variation.price * item.quantity).toFixed(2)}
      </div>
    </div>
  )
}


export default SummaryItem;